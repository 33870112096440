export default [
    {
      title: 'Dashboard',
      icon: 'HomeIcon',
      route: 'participant-home',
      resource: 'ParticipantNavs',
      action: 'read',
    },
    {
      title: 'Inbox',
      icon: 'MessageSquareIcon',
      route: 'participant-messages',
      resource: 'ParticipantNavs',
      action: 'read',
    },
  ];